import { styled } from '@stitches/react'

export const Dropdown = styled('div', {
  marginBottom: '-15px',
})

export const controls = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingTop: '18px',
})

export const button = styled('button', {
  backgroundColor: 'transparent',
  border: '1px solid black',
  color: 'black',
  width: '25%',
  height: '30px',
})

export const container = styled('div', {
  margin: '20px',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
})

export const logo = styled('div', {
  fontWeight: 'bold',
  fontSize: '22px',
  minWidth: '200px',
})

export const dropdownContainer = styled('div', {
  marginTop: '10px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

