import BuilderModelComponent from "srs.sharedcomponents/lib/esm/components/builder/srs.builderModel.components";
import { getWebsiteName } from "srs.sharedcomponents/lib/esm/utils/websiteName";
import { IBuilderModelParameter } from "srs.sharedcomponents/lib/esm/models/builder/builderModelParameter.model";

interface IBuilderComponent {
  modelKey: string;
  path: string;
  modelName: string;
}

const BuilderComponent: React.FC<IBuilderComponent> = ({
  modelKey,
  modelName,
  path,
}) => {
  const getParameters = () => {
    const websiteName = getWebsiteName()?.websiteName;
    if (websiteName) {
      const homeParameters: IBuilderModelParameter = {
        websiteName: websiteName,
      };
      return homeParameters;
    } else return {};
  };

  return (
    <BuilderModelComponent
      modelKey={modelKey}
      path={path}
      modelName={modelName}
      modelParameters={getParameters()}
    ></BuilderModelComponent>
  );
};
export default BuilderComponent;
