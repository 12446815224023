import { styled } from '@stitches/react';

export const SelectsForm = styled('form', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  color: '$formLabel',
  backgroundColor: '$primary',
  padding: '1rem',
  marginBottom: '0.1rem',
  borderRadius: 10,
  textAlign: 'left',
  'select+select': {
    marginTop: 8,
  },
  variants: {
    mainImage: {
      true: {
        width: '80%',
        height: '80%',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',

        backgroundColor: 'inherit',

        select: {
          border: '1px solid',
        },

        '@bpMin1400': { top: 40 },
        '@bp1400': { alignItems: 'center', select: { width: '50%' } },
        '@bp1200': {},
        '@bp991': {},
        '@bp767': {},
        '@bp576': {},
        '@bp410': {},
        '@bp320': {},
        '@mobile': {
          width: '100%',
          'select+select': {
            marginTop: 2,
          },
        },
      },
    },
    isKeyFinder: {
      true: {
        backgroundColor: 'transparent',
        padding: 0,
        border: '1px solid $highlight',
      },
    },
  },
})

export const Text = styled('p', {
  color: '$menuButton',
  fontFamily: "Arial",
  fontSize: "18px",
  fontWeight: "bold",

  variants: {
    mainImage: {
      true: {
        color: 'inherit',
        '@mobile': {
          marginBottom: 0,
        },
      },
    },
  },
})

export const ResetButton = styled('button', {
  width: 'max-content',
  textAlign: 'center',
  color: 'white',
  backgroundColor: '$primary',
  padding: '0.5rem 1rem 0.5rem 1rem',
  marginBottom: '0.3rem',
  borderRadius: 5,
  marginTop: '0.6rem',
  border: '2px solid white',
})
