import ChooseLogin from "components/ChooseLogin/ChooseLogin";
import LeftMenuComponent from "components/LeftMenuComponent/LeftMenuComponent";
import ServiceSupport from "components/ServiceSupport/ServiceSupport";
import { componentsConfig as rest } from "srs.sharedcomponents/lib/esm/components/config";
import CustomHeader from "srs.sharedcomponents/lib/esm/components/organisms/Header/Layout2";
import CustomLanguageDropdown from "srs.sharedcomponents/lib/esm/components/organisms/LanguageDropdown/Layout2";
import WelcomeBuilder from "srs.sharedcomponents/lib/esm/components/organisms/Welcome/BuilderContent/WelcomeBuilder";
import BrowserSuppliers from "components/BrowserSuppliers/BrowserSuppliers";
import FooterBuilderComponent from "srs.sharedcomponents/lib/esm/components/organisms/Footer/FooterBuilder/FooterBuilder";
import KeyFinder from "../KeyFinder/KeyFinder";
import ElectricVehicleCompetency from "components/ElectricVehicleCompetency/ElectricVehicleCompetency";
import EquipmentAssessment from "components/EquipmentAssessment/EquipmentAssessment";
import NewTrendigAndInitiatives from "components/NewTrendingAndInitiatives/NewTrendingAndInitiative";
import FacilitiesPlanning from "components/FacilitiesPlanning/FacilitiesPlanning";
import FinanceTerms from "components/FinanceTerms/FinanceTerms";
import EvServicing from "components/EvServicing/EvServicing";
import ToolStorage from "components/ToolStorage/ToolStorage";
import ContactUsComponent from "srs.sharedcomponents/lib/esm/shared-components/srs.contactus.component"

const componentsConfig = {
  ...rest,
  Header: CustomHeader,
  LanguageDropdown: CustomLanguageDropdown,
  WelcomeContent: WelcomeBuilder,
  ChooseLogin: ChooseLogin,
  ServiceSupport: ServiceSupport,
  LeftMenuComponent: LeftMenuComponent,
  BrowserSuppliers: BrowserSuppliers,
  FooterContent: FooterBuilderComponent,
  ElectricVehicleCompetency: ElectricVehicleCompetency,
  KeyFinder: KeyFinder,
  EquipmentAssessment: EquipmentAssessment,
  NewTrendigAndInitiatives: NewTrendigAndInitiatives,
  FacilitiesPlanning: FacilitiesPlanning,
  FinanceTerms: FinanceTerms,
  EvServicing: EvServicing,
  ToolStorage: ToolStorage,
  ContactUs: ContactUsComponent
};

export { componentsConfig };
