/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { i18nNamespaces as namespace } from "srs.sharedcomponents/lib/esm/i18n/i18n";
import { IDropDownOption } from "srs.sharedcomponents/lib/esm/models/srs.formDropdownOption.model";
import {
  useAppDispatch,
  useAppSelector,
} from "srs.sharedcomponents/lib/esm/redux/hooks";

import {
  IFacetedItem,
  IFacetedSearchRequest,
} from "srs.sharedcomponents/lib/esm/models/facetedSearch/facetedSearch.model";
import {
  getFacetedOptions,
  getFacetedConfigurations,
} from "srs.sharedcomponents/lib/esm/redux/slices/facetedSearchSlice";
import {
  getFacetedProducts,
  updateRequest,
  clearState as clearProducts,
  updateFromMainZone,
  clearProductRefineCatalogs,
  clearState,
} from "srs.sharedcomponents/lib/esm/redux/slices/facetedProductListSlice";
import * as S from "./styles";
import { FacetedSearchType } from "srs.sharedcomponents/lib/esm/utils/enums";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslate } from "srs.sharedcomponents/lib/esm/hooks/useTranslate";
import { useTranslation } from "react-i18next";
import FacetedSearchSelectInput from 'srs.sharedcomponents/lib/esm/components/molecules/FacetedSearchSelectInput/FacetedSearchSelectInput'
import { clearAll, clearRefineCatalogs } from "srs.sharedcomponents/lib/esm/redux/slices/productlistSlice";

interface IFormSchema {
  [key: string]: string;
}
interface IFacetedSearchProps {
  mainZone?: boolean;
  isKeyFinder?: boolean;
  handleReset?: () => void;
  handleShowFacetResult: (isShow: boolean) => void;
  resetInput?: boolean
  setResetInputFields: any
  refineYourSearchInput?: any[] | null
  showFacetResult?: boolean,
  setRefineYourSearchInput: React.Dispatch<React.SetStateAction<any>>;
  setShowRefineSearch: React.Dispatch<React.SetStateAction<boolean>>
  setSearchText: React.Dispatch<React.SetStateAction<any>>
}
interface LevelInfo {
  level: number;
  name: string;
}
interface FacetConfiguration {
  levelInfos: LevelInfo[];
  rootTag: string;
}

const FacetedSearch: React.FC<IFacetedSearchProps> = ({
  mainZone,
  isKeyFinder,
  handleReset,
  handleShowFacetResult,
  resetInput,
  setResetInputFields,
  showFacetResult,
  refineYourSearchInput,
  setRefineYourSearchInput,
  setShowRefineSearch,
  setSearchText
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [enableSearchButton, setEnableSearchButton] = useState<boolean>(false);
  const { facetItems, facetConfiguration } = useAppSelector(
    (state) => state.facetedSearch
  );
  const [resetInputValues, setResetInputValues] = useState<boolean>(false);
  const { fromMainZone, request: requestFromMainZone } = useAppSelector(
    (state) => state.facetedProductList
  );
  const prevRefineSearchInput = useRef(refineYourSearchInput);
  const [formInputs, setFormInputs] = useState<any>(undefined);
  const { translate } = useTranslate();
  const getOptions = useCallback(
    (level: number) => {
      let filteredItems = facetItems.filter(
        (levelItem) => levelItem.level === level
      );
      switch (level) {
        case 0:
          filteredItems = filteredItems.sort((a, b) =>
            b.name.localeCompare(a.name)
          );
          break;
        case 1:
        case 2:
        case 3:
          filteredItems = filteredItems.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          break;
        default:
          break;
      }

      const dropdownOptions = filteredItems.map((levelItem) => {
        return { label: levelItem.name, value: levelItem.name };
      });
      return [
        {
          label: t(facetConfiguration?.levelInfos[level].name ?? "", {
            ns: namespace.coreSrsPrimary,
          }),
          value: "",
        },
        ...dropdownOptions,
      ] as IDropDownOption[];
    },
    [facetItems]
  );

  const getDefaultValue = useCallback(
    (levelName: string) => {
      let defaultValue = getValues(levelName as `${number}.${string}`);
      return defaultValue;
    },
    [facetItems, resetInputValues]
  );

  const schema = yup.object().shape({
    facetItems: yup.array().of(
      yup.object().shape({
        rootTag: yup.string(),
        level: yup.number(),
        name: yup.string(),
      })
    ),
  });

  const methods = useForm<IFormSchema[]>({
    defaultValues: formInputs,

    resolver: yupResolver(schema),
    mode: "all",
  });

  const { control, getValues, setValue, reset } = methods;

  const submitForm = useCallback(
    (e) => {
      dispatch(clearProducts());
      setResetInputValues(false);
      let clear = false;
      const request = facetConfiguration?.levelInfos.map((level) => {
        let name = getValues(level.name as `${number}` | `${number}.${string}`);
        if (clear) {
          name = "";
        } else if (level.name === e.name) {
          name = e.value;
          clear = true;
        }
        return {
          level: level.level,
          name,
          rootTag: facetConfiguration.rootTag,
        };
      }) as IFacetedItem[];
      setEnableSearchButton(request.every(item => item.name !== ''))
      dispatch(updateRequest(request));
      if (mainZone) {
        dispatch(updateFromMainZone(true));
        history.push("/facetedSearch");
      }
      if (!mainZone && request && !isKeyFinder) {
        dispatch(clearProducts());
        dispatch(
          getFacetedProducts({
            refineSearchCriteria: (refineYourSearchInput ?? []).length > 0 ? refineYourSearchInput : null,
            isKeyFinderRefineSearch: true,
            facetItems: request,
            pageNumber: 0,
            pageSize: 9,
          })
        );
      }
      if (facetConfiguration?.type === FacetedSearchType.Dynamic) {
        dispatch(
          getFacetedOptions({ facetItems: request } as IFacetedSearchRequest)
        );
      }
    },
    [facetConfiguration, facetItems, isKeyFinder]
  );

  const handleSelectChange = (e: { name?: string; value: string }) => {
    const modifiedField =
      e.value === "All Years" ||
        e.value === "All Makes" ||
        e.value === "All Models"
        ? { value: "", name: e.name }
        : e;
    submitForm(modifiedField);
  };

  useEffect(() => {
    if (!fromMainZone) dispatch(clearProducts());
  }, []);

  useEffect(() => {
    if (resetInput) {
      setResetInputValues(true);
      setResetInputFields(false);
    }
  }, [resetInput]);

  useEffect(() => {
    if (!facetConfiguration) {
      dispatch(getFacetedConfigurations());
    }
    //dispatch(getFacetedOptions({} as IFacetedSearchRequest))
  }, [facetConfiguration]);

  useEffect(() => {
    if (facetConfiguration && facetItems.length > 0) {
      let request;
      if (fromMainZone) {
        request = requestFromMainZone;
        if (requestFromMainZone) {
          facetConfiguration?.levelInfos.forEach((config, index) => {
            setValue(
              config.name as `${number}` | `${number}.${string}`,
              Object.values(requestFromMainZone)[index].name as string
            );
          });
        }
        dispatch(updateFromMainZone(false));
      } else {
        request = facetConfiguration?.levelInfos.map((level) => {
          return {
            level: level.level,
            name: null,
            rootTag: facetConfiguration.rootTag,
          } as IFacetedItem;
        });

        dispatch(updateRequest(request));
      }
      if (!mainZone && request) {
        dispatch(clearProducts());
      }
    }
  }, [facetConfiguration]);

  const createFieldsAndGetOptions = async () => {
    if (facetConfiguration) {
      setFormInputs(
        facetConfiguration?.levelInfos.reduce<IFormSchema[]>((acc, cur) => {
          return [...acc, { [cur.name]: cur.name }];
        }, [])
      );

      const facetedItemsRequest = facetConfiguration.levelInfos.map((level) => {
        return {
          rootTag: facetConfiguration.rootTag,
          name: null,
          level: level.level,
        } as IFacetedItem;
      });
      dispatch(
        getFacetedOptions({
          facetItems: facetedItemsRequest,
        } as IFacetedSearchRequest)
      );
    }
  };
  useEffect(() => {
    if (!fromMainZone) createFieldsAndGetOptions();
  }, [facetConfiguration]);

  const createRequest = (facetConfiguration: FacetConfiguration) => {
    return facetConfiguration?.levelInfos.map((level) => {
      return {
        level: level.level,
        name: getValues(level.name as `${number}` | `${number}.${string}`),
        rootTag: facetConfiguration.rootTag,
      } as IFacetedItem;
    });
  };

  useEffect(() => {
  const compareArrays = (currentInput: any, prevInput: any) => {
    if (currentInput.length !== prevInput.length) {
      return false;
    }

    const sortedCurrentInput = [...currentInput].sort((a, b) => a - b);
    const sortedPrevInput = [...prevInput].sort((a, b) => a - b);
 
    return sortedCurrentInput.every((item, index) => item === sortedPrevInput[index]);
  };

    if (prevRefineSearchInput.current === undefined) {
      prevRefineSearchInput.current = refineYourSearchInput;
      return;
    }
    if (!compareArrays(refineYourSearchInput, prevRefineSearchInput.current)) {
      if (showFacetResult && facetConfiguration && (refineYourSearchInput?.length ?? 0) > 0) {
        dispatch(clearState())
        dispatch(clearAll())
        const request = createRequest(facetConfiguration);
        dispatch(
          getFacetedProducts({
            refineSearchCriteria: (refineYourSearchInput ?? []).length > 0 ? refineYourSearchInput : null,
            isKeyFinderRefineSearch: true,
            facetItems: request,
            pageNumber: 0,
            pageSize: 9,
          })
        ).then(() => {
          handleShowFacetResult(true);
        });
        prevRefineSearchInput.current = refineYourSearchInput;
      }
    }
  }, [refineYourSearchInput])

  const customSearch = () => {
    setShowRefineSearch(false)
    setRefineYourSearchInput([])
    dispatch(clearRefineCatalogs())
    dispatch(clearProductRefineCatalogs())
    if (facetConfiguration) {
      const request = createRequest(facetConfiguration);
      let searchText = '';
      request.forEach((item) => {
        searchText += item.name + ' ';
      });
      setSearchText(searchText.trim())
      if (request?.[2]?.name) {
        dispatch(updateRequest(request));
        dispatch(
          getFacetedProducts({
            refineSearchCriteria: null,
            isKeyFinderRefineSearch: true,
            facetItems: request,
            pageNumber: 0,
            pageSize: 9,
          })
        ).then(() => {
          handleShowFacetResult(true);
        });
      }
    } else {
      console.warn("facetConfiguration is undefined");
    }
  };

  function handleResetInputs(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    dispatch(clearProducts());
    reset();
    setResetInputValues(true);
    handleReset?.();

    const request = facetConfiguration?.levelInfos.map((level) => {
      return {
        level: level.level,
        name: undefined,
        rootTag: facetConfiguration.rootTag,
      } as IFacetedItem;
    });

    dispatch(updateRequest(request));
    if (!mainZone && request) {
      dispatch(clearProducts());
    }

    if (facetConfiguration?.type === FacetedSearchType.Dynamic) {
      dispatch(
        getFacetedOptions({ facetItems: request } as IFacetedSearchRequest)
      );
    }

    createFieldsAndGetOptions();
  }

  return (
    <div>
      <S.SelectsForm
        onSubmit={submitForm}
        mainImage={mainZone}
        isKeyFinder={isKeyFinder}
      >
        {!isKeyFinder && (
          <S.Text mainImage={mainZone}>
            {" "}
            {translate("FacetedSearchBrowseText")}{" "}
          </S.Text>
        )}
        {facetConfiguration?.levelInfos?.map((level) => (
          <FacetedSearchSelectInput
            key={level.name}
            control={control}
            options={getOptions(level.level)}
            name={level.name}
            onChange={handleSelectChange}
            id={level.name}
            selectedValue={getDefaultValue(level.name)}
            resetValue={resetInputValues}
            isKeyFinder={isKeyFinder}
          />
        ))}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!mainZone && (
            <S.ResetButton onClick={handleResetInputs}>
              {translate(`btnResetTooltip`)}
            </S.ResetButton>
          )}
          {isKeyFinder && (
            <div className="hideFocus ">
              <button
                style={{
                  color: enableSearchButton ? "grey" : "#adb5bd",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
                className="input-group-text btn bg-white border-rounded-right-10 border-left-0"
                id="btnsubmit"
                type="button"
                onClick={customSearch}
                disabled={!enableSearchButton}
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          )}
        </div>
      </S.SelectsForm>
    </div>
  );
};

export default FacetedSearch;
