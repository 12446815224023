import Button from "srs.sharedcomponents/lib/esm/components/atoms/Button/Button";
import { Colors } from "srs.sharedcomponents/lib/esm/styles/config";
interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  contained?: boolean;
}
const FeedBackButton = (props: IButtonProps) => {
  return (
    <Button
      style={{
        maxWidth: "max-content",
        height: 26,
        padding: "4px 12px",
        textTransform: "none",
        marginBlock: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors.blue,
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default FeedBackButton;
