import { useTranslate } from 'srs.sharedcomponents/lib/esm/hooks/useTranslate';
import * as S from './styles';

const ChooseLogin = () => {

  const { translate } = useTranslate();

  const handleLogin = (userType: string) => {
    //open external link
    switch (userType) {
      case 'Ford Dealer':
        window.open('https://www.fordtechservice.dealerconnection.com/login?back=/SPX/BOSCH-EMS.aspx?RelayState=Home&#39;', '_blank', 'noopener,noreferrer');
        break;
      case 'Lincoln Dealer':
        window.open('https://www.fordtechservice.dealerconnection.com/login?back=/SPX/BOSCH-EMS.aspx?RelayState=Home&#39;', '_blank', 'noopener,noreferrer');
        break;
      case 'Ford Authorized Fleet':
        window.open('https://www.fordtechservice.dealerconnection.com/login?back=/SPX/BOSCH-EMS.aspx?RelayState=Home&#39;', '_blank', 'noopener,noreferrer');
        break;
      case 'Retail':
        window.location.href = '/retail-login';
        break;
      case 'Guest':
        window.location.href = '/';
        break;
      default:
        break;
    }
  };

  return (
    <S.LoginContainer data-cy="choose-login-wrapper">
      <S.Center data-cy="choose-login-center">
        <S.H1Style data-cy="choose-login-header">
          {translate('txtWelcomeMessage')}
        </S.H1Style>
        <S.ParaGraph data-cy="choose-login-paragraph">
          {translate('loginChoose.descriptionText')}
        </S.ParaGraph>
        <S.ButtonsContainer data-cy="choose-login-buttons">
          <S.Button onClick={() => handleLogin('Ford Dealer')}>
            {translate('loginChoose.btnFordDealer')}
          </S.Button>
          <S.Button onClick={() => handleLogin('Lincoln Dealer')}>
          {translate('loginChoose.btnLincolnDealer')}
          </S.Button>
          <S.Button onClick={() => handleLogin('Ford Authorized Fleet')}>
            {translate('loginChoose.btnFordAuthorizedFleet')}
          </S.Button>
          <S.Button onClick={() => handleLogin('Retail')}>
            {translate('loginChoose.btnRetail')}
          </S.Button>
          <S.Button onClick={() => handleLogin('Guest')}>
            {translate('loginChoose.btnGuest')}
          </S.Button>
        </S.ButtonsContainer>
      </S.Center>
    </S.LoginContainer>
  );
};

export default ChooseLogin;