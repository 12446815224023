import React from 'react';
import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks';
import CustomModelComponent from 'srs.sharedcomponents/lib/esm/components/builder/CustomModelComponents/CustomModelComponent';

interface IEvServicing {
  modelKey: string;
  path: string;
  modelName: string;
}

const EvServicing: React.FC<IEvServicing> = ({
  modelKey,
  modelName,
  path,
}) => {
  const welcome = useAppSelector((state) => state.welcome.welcome);

  if (!welcome) return <></>;
  else
    return (
        <CustomModelComponent
          modelKey={modelKey ||"Ev-Servicing"}
          path={path ||"/ev-servicing"}
          modelName={modelName || "content-page"}
        />
    );
};

export default EvServicing;
