import { styled } from "@stitches/react";

export const LoginContainer = styled('div', {
  textAlign: 'center',
  width: '100%',
});

export const Center = styled('div', {
  margin: '90px auto',
  width: '80%',
  maxWidth: '1670px',
  padding: '60px 20px',
  borderRadius: '5px',
  minHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#f2f2f2', // Light grey color
  "@media (max-width: 768px)": {
    width: '100%',
    padding: '20px 10px',
  },
});

export const H1Style = styled('h1', {
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '20px',
});

export const ParaGraph = styled('p', {
  fontSize: '1rem',
  fontWeight: 'normal',
});

export const ButtonsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: '90px',
  "@media (max-width: 992px)": {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: '20px',
  },
});

export const Button = styled('button', {
  padding: '10px 20px',
  margin: '0 5px',
  border: 'none',
  borderRadius: '25px',
  backgroundColor: '#00095b', // Navy blue color
  color: 'white',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: '#01094a', // Lighter navy blue
  },
  width: '195px',
  height: '50px',
  fontSize: '15px',
  alignContent: 'center',

  "@media (max-width: 992px)": {
    width: '47%',
    flexDirection: 'column',
    marginBottom: '10px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
    fontSize: '14px',
  },
});