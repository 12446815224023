// Styled components using Stitches
import { styled } from '@stitches/react';

export const Container = styled('div', {
  width: '90%',
  margin: 'auto',
  fontFamily: 'Arial, sans-serif',
});

export const Breadcrumbs = styled('div', {
  marginTop: '20px',
});

export const BreadcrumbLink = styled('a', {
  color: '#007bff',
  textDecoration: 'none',
});

export const BreadcrumbSpan = styled('span', {
  color: '#6c757d',
});

export const Title = styled('h1', {
  marginTop: '20px',
  fontSize: '24px',
});

export const Alphabet = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '15px',
  margin: '20px 0',
  backgroundColor: '#f2f2f2',
  padding: '20px',
  marginBottom: '20px',
  '@media (max-width: 768px)': {
    padding: '20px',
  },
});

export const AlphabetLink = styled('a', {
  width: '15%',
  textDecoration: 'none',
  color: '#007bff',
  height: '42px',
  fontSize: '20px',
  '@media (max-width: 768px)': {
    width: '22%',
  },
});

export const SupplierListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
  'h2': {
    width: '100%',
    fontSize: '22px',
    margin: '20px 0 10px',
    fontWeight: 'bold',
  },
});

export const SupplierColumn = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '5px',
  marginBottom: '30px',
});

export const SupplierLink = styled('a', {
  display: 'block',
  color: '#007bff',
  textDecoration: 'none',
  marginBottom: '5px',
  width: '30%',
  fontSize: '17px',
});