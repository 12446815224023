import React from "react";
import { useAppSelector } from "srs.sharedcomponents/lib/esm/redux/hooks";
import CustomModelComponent from "srs.sharedcomponents/lib/esm/components/builder/CustomModelComponents/CustomModelComponent";

interface IEquipmentAssessment {
  modelKey: string;
  path: string;
  modelName: string;
}

const EquipmentAssessment: React.FC<IEquipmentAssessment> = ({
  modelKey,
  modelName,
  path,
}) => {
  const welcome = useAppSelector((state) => state.welcome.welcome);

  if (!welcome) return <></>;
  else
    return (
        <CustomModelComponent
          modelKey={modelKey || "Equipment-Assessment"}
          path={path || "/equipment-assessment"}
          modelName={modelName || "content-page"}
        />
    );
};

export default EquipmentAssessment;
