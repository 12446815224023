import { styled } from "@stitches/react";
import { Link } from 'react-router-dom'

export const LeftMenuMainItem = styled('li', {
  display: 'flex',
  fontSize: '22px !important',
  color: '$leftMenuItem !important',
  flexDirection: 'column',
  padding: '0.5rem 0.9rem',
  border: '0 !important',
  marginBottom: '0.7rem',
})

export const LeftMenuStyleComponent = styled(Link, {
  padding: '0.2rem 1rem 0.2rem',
  fontSize: '18px !important',
  fontWeight: '500',
  color: '$leftMenuItem !important',
  lineHeight: '$sidebar-line-height',
  marginTop: '-1px',
  borderTopWidth: '1px',
  variants: {
    activated: {
      true: {
        color: '$leftMenuItem',
      },
    },
  },
})

export const LeftMenuHeader = styled('div', {
  display: 'none',
  '@mobile': {
    display: 'flex',
    justifyCotent: 'space-between',
    alignContent: 'center',
    padding: '0.3rem 0.9rem',
    background: '$primary',
    color: '$buttonTextColor',
  },
})
