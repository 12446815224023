import { globalCss } from "srs.sharedcomponents/lib/esm/styles";

export const globalStyles = globalCss({
  "*": {
    border: "none",
    padding: 0,
    margin: 0,
    fontFamily: 'FordF1-Regular !important'
  },
  'dock-privacy-settings':{
    fontFamily: 'FordF1-Regular !important'
  },
  a: {
    color: "inherit",
  },
  '.fa': {
    fontFamily: 'FontAwesome !important',
  }
});
