/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslate } from "srs.sharedcomponents/lib/esm/hooks/useTranslate";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import * as S from "./styles";
import { useEffect, useState } from "react";
import ProductListComponent from "srs.sharedcomponents/lib/esm/shared-components/srs.productList.component";
import FacetedSearchPage from "srs.sharedcomponents/lib/esm/components/pages/FacetedSearch/FacetedSeachPage";
import FacetedSearch from "../FacetedSearch/FacetedSearch";
import { stringToBoolean } from "srs.sharedcomponents/lib/esm/utils/helpers";
import RefineYourSearch from "components/RefineYourSearch/RefineYourSearch";
import { useAppSelector, useAppDispatch } from "srs.sharedcomponents/lib/esm/redux/hooks";
import {
  selectIsSearching,
  totalItems,
  loading,
  clearRefineCatalogs,
  clearAll,
} from "srs.sharedcomponents/lib/esm/redux/slices/productlistSlice";
import { IItemShortResponse } from "srs.sharedcomponents/lib/esm/models/responses/srs.itemShortResponse.model";
import { useLocation } from "react-router-dom";
import { ISiteSettings } from "srs.sharedcomponents/lib/esm/models/srs.siteSettings";
import { siteSettings } from "srs.sharedcomponents/lib/esm/redux/slices/welcomeSlice";
import BreadcrumbComponent from "srs.sharedcomponents/lib/esm/components/srs.breadcrumb.component";
import { clearProductRefineCatalogs, clearState } from "srs.sharedcomponents/lib/esm/redux/slices/facetedProductListSlice";

interface IFacetedSearchProps {
  itemListPlaceholder?: () => React.ReactNode;
  isKeyFinder?: boolean;
}
function KeyFinder({
  itemListPlaceholder,
  isKeyFinder,
}: Readonly<IFacetedSearchProps>) {
  const { translate } = useTranslate();
  const [selectedRefineSearchItems, setSelectedRefineSearchItems] = useState<any[] | null>([]);
  const [keyPartNumberSearchValue, setKeyPartNumberSearchValue] = useState("");
  const [vinSearchValue, setVinSearchValue] = useState("");
  const [keyPartValue, setKeyPartValue] = useState("");
  const [vinValue, setVinValue] = useState("");
  const [showItems, setShowItems] = useState(false);
  const [searchPressed, setSearchPressed] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showRefineSearch, setShowRefineSearch] = useState(false)
  const [showFacetResult, setShowFacetResult] = useState<boolean>(false);
  const { search } = useLocation();
  const isSearching = useAppSelector(selectIsSearching);
  const dispatch = useAppDispatch();
  const isLoading: boolean = useAppSelector(loading);
  const settings: ISiteSettings | undefined = useAppSelector(siteSettings);
  const [resetInputFields, setResetInputFields] = useState<boolean>(false)
  const maxAllowedCartItems = settings?.["Srs.MaxAllowedCartItems"] ?? "0";
  const searchParams = new URLSearchParams(search);
  let searchParam = decodeURI(searchParams.get("search") ?? "").trim();
  const isKeyPartSearchEnabled = keyPartNumberSearchValue.length >= 3;
  const isVinSearchEnabled = vinSearchValue.length == 17;
  const fullWidthLayout = stringToBoolean(
    process.env.REACT_APP_FULL_WIDTH_LAYOUT
  );
  const total: number = useAppSelector(totalItems);
  const searchItems: IItemShortResponse[] | undefined = useAppSelector(
    (state) => state.productList.productList.items
  );

  useEffect(() => {
    if (searchPressed) {
      setShowFacetResult(false);
      setSearchPressed(false);
    }
  }, [searchPressed, keyPartNumberSearchValue, vinSearchValue]);

  useEffect(()=>{
    return ()=> {
      handleReset()
      setResetInputFields(true)
    }
  },[])

  useEffect(()=>{
    if((keyPartValue || vinValue) && !searchPressed && showItems){
      setShowRefineSearch(true)
  }},[searchItems])

  const { reset } = useForm({
    defaultValues: {
      make: "",
      model: "",
      year: "",
    },
    mode: "onBlur",
  });

  const validationSchema = Yup.object().shape({
    search: Yup.string(),
    vin: Yup.string().when("search", {
      is: (search: string | undefined) => !search, // Explicitly type 'search'
      then: Yup.string().min(17, "VIN must be at least 17 characters long"),
      otherwise: Yup.string(),
    }),
    make: Yup.string(),
    model: Yup.string(),
    year: Yup.string(),
  });

  const handleSearch = async (type: string) => {
    setSelectedRefineSearchItems([])
    try {
      await validationSchema.validate({
        search: keyPartNumberSearchValue,
        vin: vinSearchValue,
      });

      if (type === "part-number") {
        setKeyPartValue(keyPartNumberSearchValue);
        setVinValue("");
        setVinSearchValue("");
        setSearchText(keyPartNumberSearchValue);
      } else if (type === "vin") {
        setKeyPartNumberSearchValue("");
        setKeyPartValue("");
        setVinValue(vinSearchValue);
        setSearchText(vinSearchValue);
      }
  
      const isKeyPartNumberValid = keyPartNumberSearchValue && keyPartNumberSearchValue.length >= 3;
      const isVinValid = vinSearchValue && vinSearchValue.length > 16;
  
      if (isKeyPartNumberValid || isVinValid) {
        setResetInputFields(true)
        setSearchPressed(true)
        dispatch(clearRefineCatalogs())
        dispatch(clearProductRefineCatalogs())
        dispatch(clearAll())
        dispatch(clearState())
        setShowItems(true)
        setShowRefineSearch(false)
      }

    } catch (error: any) {}
  };

  const handleReset = () => {
    setShowItems(false)
    setShowRefineSearch(false)
    setKeyPartValue("");
    setVinValue("");
    setKeyPartNumberSearchValue("");
    setVinSearchValue("");
    clearForm();
  };

  const clearForm = () => {
    setKeyPartNumberSearchValue("");
    setVinSearchValue("");
    setSearchText("")
    setSearchPressed(false);
    reset({
      make: "",
      model: "",
      year: "",
    });
  };
  const canShowSearchTerm = () => {
    return !isLoading && isSearching && searchParam;
  };

  return (
    <>
      <Container fluid={fullWidthLayout}>
        <BreadcrumbComponent />

        {(canShowSearchTerm() ||
          keyPartNumberSearchValue ||
          vinSearchValue) && (
            <div className="bread-and-message d-flex justify-content-center">
              {keyPartNumberSearchValue && (
                <div>
                  {translate(`SearchResults_Text`).replace(
                    "{0}",
                    keyPartNumberSearchValue
                  )}
                </div>
              )}
              {searchParam && (
                <div>
                  {translate(`SearchResults_Text`).replace("{0}", searchParam)}
                </div>
              )}
              {vinSearchValue && (
                <div>
                  {translate(`SearchResults_Text`).replace(
                    "{0}",
                    vinSearchValue
                  )}
                </div>
              )}
              {total > 0 && (
                <div>
                  {translate(`lblLimitOrderMsg`).replace(
                    "{count}",
                    maxAllowedCartItems
                  )}
                </div>
              )}
            </div>
        )}
      </Container>
      <Container
        fluid={fullWidthLayout}
        style={{ display: "flex", justifyContent: "space-between" }}
        className="item__details"
      >
        <S.container>
          <Col>
            <S.logo className="pb-3">
              {translate(`serviceSupport.menu2.title`)}
            </S.logo>
            <form className="form-group m-0 text-right w-full">
              <div
                style={{ marginBottom: "-5px" }}
                className="input-group mr-sm-2 hideFocus"
              >
                <div
                  id="key-part-number"
                  className="input-group mb-2 mr-sm-2 hideFocus"
                >
                  <label htmlFor="part-number" className="sr-only">
                    {translate(`serviceSupport.menu2.item1`)}
                  </label>
                  <input
                    style={{ borderRadius: "4px" }}
                    type="text"
                    className="form-control form-control-lg"
                    id="part-number"
                    name="part-number"
                    value={keyPartNumberSearchValue}
                    onChange={(e) =>
                      setKeyPartNumberSearchValue(e.target.value)
                    }
                    placeholder={translate(`serviceSupport.menu2.item1`)}
                  />
                  <div className="header__input-group-append">
                    <button
                      style={{
                        color: isKeyPartSearchEnabled ? "grey" : "#adb5bd",
                      }}
                      className="input-group-text btn border-rounded-right-10 border-left-0"
                      id="btnsubmit"
                      type="button"
                      onClick={() => handleSearch("part-number")}
                      disabled={!isKeyPartSearchEnabled}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div>
                </div>

                <div id="vin" className="input-group mb-2 mr-sm-2 hideFocus">
                  <label htmlFor="vin" className="sr-only">
                    {translate(`serviceSupport.menu2.item2`)}
                  </label>
                  <input
                    style={{ borderRadius: "4px" }}
                    type="text"
                    className="form-control form-control-lg"
                    id="vin"
                    name="vin"
                    value={vinSearchValue}
                    maxLength={17}
                    onChange={(e) => setVinSearchValue(e.target.value)}
                    placeholder={translate(`serviceSupport.menu2.item2`)}
                  />
                  <div className="header__input-group-append">
                    <button
                      disabled={!isVinSearchEnabled}
                      style={{ color: isVinSearchEnabled ? "grey" : "#adb5bd" }}
                      className="input-group-text btn bg-white border-rounded-right-10 border-left-0"
                      id="vinsubmit"
                      type="button"
                      onClick={() => handleSearch("vin")}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div>
                </div>
              </div>
              <hr style={{ marginTop: "17px" }} />
              <S.dropdownContainer className="input-group mr-sm-2 hideFocus">
                <FacetedSearch
                  handleShowFacetResult={(isShow: boolean) => {
                    setKeyPartNumberSearchValue("");
                    setKeyPartValue("");
                    setVinSearchValue("");
                    setVinValue("");
                    setShowFacetResult(isShow);
                    setShowItems(true)
                    setShowRefineSearch(true)
                  }}
                  showFacetResult={showFacetResult}
                  isKeyFinder={true}
                  handleReset={handleReset}
                  resetInput = {resetInputFields}
                  setResetInputFields = {setResetInputFields}
                  refineYourSearchInput={selectedRefineSearchItems}
                  setRefineYourSearchInput={setSelectedRefineSearchItems}
                  setShowRefineSearch = {setShowRefineSearch}
                  setSearchText= {setSearchText}
                />
              </S.dropdownContainer>
              <hr style={{ margin: "0px" }} />
            </form>
            <Container style={{ paddingLeft: "0px" ,paddingRight:"0px"}}>
              <S.dropdownContainer className="input-group mr-sm-2 hideFocus">
                { showRefineSearch && (
                    <RefineYourSearch
                    searchText={searchText}
                    refineYourSearchValues={selectedRefineSearchItems}
                    setRefineYourSearchValues={setSelectedRefineSearchItems}
                      isKeyFinder={isKeyFinder}
                      onSelectionChange={(selected) =>{
                        setShowItems(false)
                        setSelectedRefineSearchItems([...selected])
                        setShowItems(true)               
                      }}
                    />
                  )}
              </S.dropdownContainer>
            </Container>
          </Col>
        </S.container>
        <Container>
          {(keyPartValue || vinValue) && !searchPressed && showItems ? (
            <ProductListComponent
              keyPartSearchValue={keyPartValue}
              vinSearchValue={vinValue}
              isKeyFinder={true}
              refineYourSearchInput={selectedRefineSearchItems}
            />
          ) : (
            showFacetResult  && showItems && (
              <FacetedSearchPage
                isKeyFinder={true}
                itemListPlaceholder={itemListPlaceholder}
                refineYourSearchInput={selectedRefineSearchItems}
              />
            )
          )}
        </Container>
      </Container>
    </>
  );
}

export default KeyFinder;
