import CustomModelComponent from "srs.sharedcomponents/lib/esm/components/builder/CustomModelComponents/CustomModelComponent";
import { useAppSelector } from "srs.sharedcomponents/lib/esm/redux/hooks";

interface INewTrendigAndInitiatives {
    modelKey: string;
    path: string;
    modelName: string;
  }
  
  const NewTrendigAndInitiatives: React.FC<INewTrendigAndInitiatives> = ({
    modelKey,
    modelName,
    path,
  }) => {
    const welcome = useAppSelector((state) => state.welcome.welcome);
  
    if (!welcome) return <></>;
    else
      return (
          <CustomModelComponent
            modelKey={modelKey || "New-Trendig-Initiatives"}
            path={path || "/new-trending-initiatives"}
            modelName={modelName || "content-page"}
          />
      );
  };
  
  export default NewTrendigAndInitiatives;