import {
  appConfig as packageAppConfig,
  IConfig,
} from "srs.sharedcomponents/lib/esm/config/app.config";

export const appConfig: IConfig = {
  ...packageAppConfig,
  enableProductListLayoutChange: true,
  disableContinueShoppingButton: true,
  disableWishListButton: true,
  disableLeftNavForProductListCatalogue: false,
};
