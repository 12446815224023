import { styled } from "@stitches/react";
import { Colors } from "srs.sharedcomponents/lib/esm/styles/config";

export const Title = styled("h1", {
  fontSize: "1.2rem",
  fontWeight: "bold",
  textAlign: "left",
  marginBottom: "1rem",
  color: Colors.primary,
});

export const Wrapper = styled("div", {
  marginTop: "4rem",
  marginBottom: "6rem",
});

export const BackButton = styled("button", {
  background: "none",
  color: Colors.blue,
  fontWeight: 600,
});
