import AccordionMUI from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";

import AccordionSummary from "../molecules/AccordionSummary";

interface IProps {
  expanded?: boolean;
  onChange?: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  children: React.ReactNode;
  title: string;
  noPadding?: boolean;
}

const Accordion = ({
  children,
  title,
  expanded,
  onChange,
  noPadding,
}: IProps) => {
  return (
    <AccordionMUI expanded={expanded} onChange={onChange}>
      <AccordionSummary title={title} />
      <AccordionDetails style={noPadding ? { padding: 0 } : {}}>
        {children}
      </AccordionDetails>
    </AccordionMUI>
  );
};

export default Accordion;
