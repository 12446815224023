import CustomModelComponent from "srs.sharedcomponents/lib/esm/components/builder/CustomModelComponents/CustomModelComponent";
import { useAppSelector } from "srs.sharedcomponents/lib/esm/redux/hooks";

interface IFinanceTerms {
    modelKey: string;
    path: string;
    modelName: string;
  }
  
  const FinanceTerms: React.FC<IFinanceTerms> = ({
    modelKey,
    modelName,
    path,
  }) => {
    const welcome = useAppSelector((state) => state.welcome.welcome);
  
    if (!welcome) return <></>;
    else
      return (
          <CustomModelComponent
            modelKey={modelKey || "Finance-Terms"}
            path={path || "/finance-terms"}
            modelName={modelName || "content-page"}
          />
      );
  };
  
  export default FinanceTerms;