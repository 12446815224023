import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks'
import { Container, Row, Col } from 'react-bootstrap'
import CustomModelComponent from 'srs.sharedcomponents/lib/esm/components/builder/CustomModelComponents/CustomModelComponent'

interface IBuilderUIComponent {
  modelKey: string;
  path: string;
  modelName: string;
}
const ElectricVehicleCompetency: React.FC<IBuilderUIComponent> = ({
  modelKey,
  modelName,
  path,
}) => {
  const welcome = useAppSelector((state) => state.welcome.welcome)
  
if (!welcome ) return <></>
else return (
        <Container>
            <main id="main" role="main" className="pb-3">
                <Row>
                    <Col Col md={12}>
                        <Row>
                            <div className="col-12">
                                <CustomModelComponent 
                                    modelKey={modelKey || 'electric-vehicle-competency'}
                                    path={path || '/electric-vehicle-competency'}
                                    modelName={modelName ||'content-page'} 
                                />
                            </div>
                        </Row>
                    </Col>
                </Row>
            </main>
        </Container>
    )
}
export default ElectricVehicleCompetency