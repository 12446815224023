import { useHistory, useLocation } from "react-router-dom";
import Accordion from "./organisms/Accordion";
import * as S from "./style";
import BuilderComponent from "components/ServiceSupport/molecules/BuilderComponent/BuilderComponent";
import queryString from "query-string";
import BuilderModelComponent from "srs.sharedcomponents/lib/esm/components/builder/srs.builderModel.components";
import { getWebsiteName } from "srs.sharedcomponents/lib/esm/utils/websiteName";
import { IBuilderModelParameter } from "srs.sharedcomponents/lib/esm/models/builder/builderModelParameter.model";
import { LinksBuilderContent } from "./builderStyles";
import { useTranslate } from "srs.sharedcomponents/lib/esm/hooks/useTranslate";
import ContactUs from "srs.sharedcomponents/lib/esm/components/organisms/FeedbackForm/FeedbackForm";
import FeedBackButton from "./atoms/FeedBackButton/FeedBackButton";
import { useEffect, useState } from "react";
import { useAppDispatch } from "srs.sharedcomponents/lib/esm/redux/hooks";
import { getContact } from "srs.sharedcomponents/lib/esm/redux/slices/contactUsSlice";

const ServiceSupport = () => {
  const navigation = useHistory();
  const { search } = useLocation();
  const { expanded } = queryString.parse(search);
  const { translate } = useTranslate();
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const dispatch = useAppDispatch();
  const handleChange = (panel: string) => {
    const isPanelExpanded = expanded === panel;

    if (isPanelExpanded) {
      navigation.push(`?expanded=`);
    } else {
      navigation.push(`?expanded=${panel}`);
    }
  };
  const getHomeParameters = () => {
    const websiteName = getWebsiteName()?.websiteName;
    if (websiteName) {
      const homeParameters: IBuilderModelParameter = {
        websiteName: websiteName,
      };
      return homeParameters;
    } else return {};
  };
  useEffect(() => {
    dispatch(getContact());
  }, []);
  return (
    <S.Wrapper>
      <div className="container d-flex justify-content-center">
        <div className="col-10">
          <S.Title>{translate("serviceSupport.menu3.title")}</S.Title>

          <Accordion
            title={translate("serviceSupport.menu3.item1")}
            expanded={expanded === "contact-us"}
            onChange={() => handleChange("contact-us")}
          >
            <BuilderComponent
              modelKey="service-and-support-contact-us"
              path="/service-support"
              modelName="service-and-support-contact-us"
            />
          </Accordion>

          <Accordion
            title={translate("serviceSupport.menu3.item2")}
            expanded={expanded === "find-a-sales-rep"}
            onChange={() => handleChange("find-a-sales-rep")}
          >
            <BuilderComponent
              modelKey="sales-rep-accordion"
              path="/service-support"
              modelName="sales-rep-accordion"
            />
          </Accordion>

          <Accordion
            title={translate("serviceSupport.menu3.item3")}
            expanded={expanded === "order-insights"}
            onChange={() => handleChange("order-insights")}
          >
            <LinksBuilderContent>
              <BuilderModelComponent
                modelKey="facilitiesPlaning"
                path="/facilitiesPlaning"
                modelName="facilities-planing"
                modelParameters={getHomeParameters()}
              ></BuilderModelComponent>
            </LinksBuilderContent>
          </Accordion>

          <Accordion
            noPadding
            title={translate("serviceSupport.menu3.item4")}
            expanded={expanded === "faq"}
            onChange={() => handleChange("faq")}
          >
            {showFeedbackForm ? (
              <>
                <S.BackButton onClick={() => setShowFeedbackForm(false)}>
                  {"<"}
                  {translate("serviceSupport.menu3.item4")}
                </S.BackButton>
                <div style={{ padding: "0 12px 12px 12px" }}>
                  <ContactUs />
                </div>
              </>
            ) : (
              <>
                <BuilderComponent
                  modelKey="faq"
                  path="/faq"
                  modelName="faq-rotunda"
                />
                <FeedBackButton
                  onClick={() => (window.location.href = "/feedback")}
                >
                  {translate("serviceSupport.askQuestion")}
                </FeedBackButton>
              </>
            )}
          </Accordion>

          <Accordion
            title={translate("serviceSupport.menu3.item5")}
            expanded={expanded === "policies"}
            onChange={() => handleChange("policies")}
          >
            <LinksBuilderContent>
              <BuilderModelComponent
                modelKey="policesLinks"
                path="/policesLinks"
                modelName="header-menu-option"
                modelParameters={getHomeParameters()}
              ></BuilderModelComponent>
            </LinksBuilderContent>
          </Accordion>
        </div>
      </div>
    </S.Wrapper>
  );
};

export default ServiceSupport;
