import CustomModelComponent from "srs.sharedcomponents/lib/esm/components/builder/CustomModelComponents/CustomModelComponent";
import { useAppSelector } from "srs.sharedcomponents/lib/esm/redux/hooks";

interface IToolStorage {
    modelKey: string;
    path: string;
    modelName: string;
  }
  
  const ToolStorage: React.FC<IToolStorage> = ({
    modelKey,
    modelName,
    path,
  }) => {
    const welcome = useAppSelector((state) => state.welcome.welcome);
  
    if (!welcome) return <></>;
    else
      return (
          <CustomModelComponent
            modelKey={modelKey || "Tool-Storage"}
            path={path || "/tool-storage"}
            modelName={modelName || "content-page"}
          />
      );
  };
  
  export default ToolStorage;