import ChevronDown from 'srs.sharedcomponents/lib/esm/assets/icons/chevronDown';
import { styled } from '@mui/material/styles'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'

import Typography from '@mui/material/Typography'


const AccordionSummaryStyled = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ChevronDown />} {...props} />
))(({ theme }) => ({
  height: '55px',
  padding: 0,
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  paddingRight: '10px',
  paddingLeft: '10px',
  '@media (max-width: 780px)': {
    paddingTop: '5px',
    paddingBottom: '5px',
    height: 'auto',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    filter: 'grayscale(100%)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-180deg)',
    filter: 'grayscale(0%)',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    justifyContent: 'space-between',
    paddingRight: '10px',
  },
  '.MuiTypography-root': {
    '@media (max-width: 780px)': {
      fontSize: '14px',
      lineHeight: '1.7',
    },
  },
}))


interface IProps {
  title: string
}

const AccordionSummary = ({ title }: IProps) => {
  return (
    <AccordionSummaryStyled
        expandIcon={<ChevronDown />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>
          {title}
        </Typography>
    </AccordionSummaryStyled>
  )
}

export default AccordionSummary;